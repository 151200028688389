import BasicComponentInterface from "interfaces/BasicComponentInterface";
import Environment from "models/Environment/Environment";
import { useState } from "react";
import WarningNotification from "./WarningNotification";


interface OutOfDateNotificationProps extends BasicComponentInterface {
  softwareName?: string;
  message: string;
  latestVersion: string;
  currentVersion: string;
}

const OutOfDateNotification = ({
  softwareName = "Software",
  message,
  latestVersion,
  currentVersion,
}: OutOfDateNotificationProps) => {
  const [showNotification, setShowNotification] = useState(true);
  let splitLatestVersion = Environment.splitVersionNumbers(latestVersion);
  let splitCurrentVersion = Environment.splitVersionNumbers(currentVersion);
  if (Environment.isVersionLessThan(splitCurrentVersion, splitLatestVersion)) {
    return (
      <WarningNotification
        message={(
          <p>
          {message}
        </p>
        )}
        titleLeft={(
          <h2>New <strong>{softwareName}</strong> Version Available</h2>
        )}
        titleRight={(
          <div className="out-of-date-notification__title__versions">
            <div className="out-of-date-notification__title__versions__name">
              Latest Version:
            </div>
            <div className="out-of-date-notification__title__versions__number">
              <strong>{latestVersion}</strong>
            </div>
            <div className="out-of-date-notification__title__versions__name">
              Your Version:
            </div>
            <div className="out-of-date-notification__title__versions__number">
              <strong>{currentVersion}</strong>
            </div>
          </div>
        )}
        showNotification={showNotification}
      />
    )
  }
  else return null;
}

export default OutOfDateNotification;