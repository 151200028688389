import { useEffect, useState } from "react";
import BasicComponentInterface from "interfaces/BasicComponentInterface";
import PluginEnvironment from "models/Environment/PluginEnvironment";
import { API } from "helpers/API";
import OutOfDateNotification from "./OutOfDateNotification";

interface PluginVersionNotificationProps extends BasicComponentInterface {
  currentPluginEnvironment: PluginEnvironment
}

const PluginVersionNotification = ({
  currentPluginEnvironment,
}: PluginVersionNotificationProps) => {
  const [latestPluginVersion, setLatestPluginVersion] = useState("");
  // Get the current version of the plugin from Firebase
  useEffect(() => {
    API.getData(`Download/Plugins/${currentPluginEnvironment.application.name}/Latest/Version`).then(data => {
      if(data) {
        console.log("Latest Plugin Version: ", data);
        data.replace("2022", "1");
        setLatestPluginVersion(data);
      }
      else {
        console.error("Could not get latest plugin version.");
      }
    })
  }, []);
  return (
    <OutOfDateNotification
      softwareName={`${currentPluginEnvironment.application.name} Plugin`}
      currentVersion={currentPluginEnvironment.pluginVersion}
      latestVersion={latestPluginVersion}
      message={`You are using an outdated version of the Stage Plugin for ${currentPluginEnvironment.application.name}. Please update to the latest version to ensure you are using the latest features and bug fixes.`}
    />
  )
}

export default PluginVersionNotification;