

export enum SettingType {
  STRING = 'STRING',
  INTEGER = 'INTEGER',
  FLOAT = 'FLOAT',
  BOOLEAN = 'BOOLEAN'
}


export class Setting {
  private _type: SettingType;
  private _name: string;
  private _displayName: string;
  private _tooltipDescription: string;
  private _value: any;
  private _defaultValue: any;

  constructor(type: SettingType, name: string, displayName: string, tooltipDescription: string, defaultValue: any) {
    this._type = type;
    this._name = name;
    this._displayName = displayName;
    this._tooltipDescription = tooltipDescription;
    this._defaultValue = defaultValue;
    this._value = defaultValue;
  }

  public get type() {
    return this._type;
  }
  public set type(type: SettingType) {
    this._type = type;
  }

  public get name() {
    return this._name;
  }
  public set name(name: string) {
    this._name = name;
  }
  
  public get displayName() {
    return this._displayName;
  }
  public set displayName(displayName: string) {
    this._displayName = displayName;
  }

  public get tooltipDescription() {
    return this._tooltipDescription;
  }
  public set tooltipDescription(tooltipDescription: string) {
    this._tooltipDescription = tooltipDescription;
  }

  public get value() {
    return this._value;
  }
  public set value(value: any) {
    this._value = value;
  }

  public get defaultValue() {
    return this._defaultValue;
  }
  public set defaultValue(defaultValue: any) {
    this._defaultValue = defaultValue;
  }


  static fromJSON(json: any): Setting {
    return new Setting(json.type, json.name, json.displayName, json.tooltipDescription, json.defaultValue);
  }
}

export class SettingsSection {
  private _name: string;
  private _displayName: string;
  private _tooltipDescription: string;
  private _settings: Map<string, Setting>;

  constructor(name: string, displayName: string, tooltipDescription: string, settings: Map<string, Setting>) {
    this._name = name;
    this._displayName = displayName;
    this._tooltipDescription = tooltipDescription;
    this._settings = settings;
  }

  public get name() {
    return this._name;
  }
  public set name(name: string) {
    this._name = name;
  }

  public get displayName() {
    return this._displayName;
  }
  public set displayName(displayName: string) {
    this._displayName = displayName;
  }

  public get tooltipDescription() {
    return this._tooltipDescription;
  }
  public set tooltipDescription(tooltipDescription: string) {
    this._tooltipDescription = tooltipDescription;
  }

  public get settings() {
    return this._settings;
  }
  public set settings(settings: any) {
    this._settings = settings;
  }
  

  static fromJSON(json: any): SettingsSection {
    console.log("Creating settings section from JSON", json)
    let settings: any = {};
    if (json['settings']) {
      json.settings.forEach((setting: any) => {
        settings[setting.name] = Setting.fromJSON(setting);
      });
    }
    return new SettingsSection(json.name, json.displayName, json.tooltipDescription, settings);
  }

}

export default class CustomSettings {
  private _sections: any;

  constructor(sections: any) {
    this._sections = sections;
  }

  public get sections() {
    return this._sections;
  }
  public set sections(sections: any) {
    this._sections = sections;
  }

  static fromJSON(json: any): CustomSettings {
    console.log("Creating custom settings from JSON", json)
    let sections: any = {}; 
    if (json['settingSections']) {
      json.settingSections.forEach((section: any) => {
        sections[section.name] = SettingsSection.fromJSON(section);
      });
    }
    return new CustomSettings(sections);
  }
}