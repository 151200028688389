import EnvironmentApplication from "./EnvironmentApplication";
import FeatureEnvironment, { FEATURE_LICENSE_TYPE, FEATURE_PLATFORM_TYPE, FEATURE_VERSION_TYPE } from "./FeatureEnvironment";
import PluginEnvironment, { PLUGIN_LICENSE_TYPE, PLUGIN_PLATFORM_TYPE, PLUGIN_VERSION_TYPE } from "./PluginEnvironment";
import PluginOptions from "./PluginOptions";

interface Application {
  name: string;
  version: PLUGIN_VERSION_TYPE | FEATURE_VERSION_TYPE;
}

/**
 * Base environment class with the common properties used by other 
 * environment classes
 */
export default class Environment<PlatformType = string, VersionType = string, LicenseType = string> {
  static VERSION_SPLIT_CHARACTERS = /[\s\._-]/g
  protected _platform: PlatformType = "" as PlatformType;
  protected _application: EnvironmentApplication<VersionType> | EnvironmentApplication<VersionType>[] = new EnvironmentApplication<VersionType>('', '' as VersionType);
  protected _stageVersion: VersionType = "" as VersionType;
  protected _sdkVersion: VersionType = "" as VersionType;
  protected _pluginVersion: VersionType = "" as VersionType;
  protected _license: LicenseType = '' as LicenseType;
  public options: PluginOptions;

  /**
   * 
   * @param platform: string should be Mac, Windows, or Linux
   * @param applicationName: Code/Name of application being used Examples are VStitcher and CLO3D
   * @param applicationVersion: Version of the application being used
   * @param stageVersion: Version of the stage being used
   * @param sdkVersion: Version of the SDK being used
   * @param pluginVersion: Version of the plugin being used
   * @param license: License of the application being used
   */
  constructor(
    platform: PlatformType = "" as PlatformType,
    application: EnvironmentApplication<VersionType> | EnvironmentApplication<VersionType>[] = new EnvironmentApplication<VersionType>('', '' as VersionType), 
    pluginVersion: VersionType = "" as VersionType, 
    stageVersion: VersionType = "" as VersionType, 
    sdkVersion: VersionType = "" as VersionType, 
    license: LicenseType = '' as LicenseType,
    options: PluginOptions = new PluginOptions()) {
    this.platform = platform;       // Supported platforms are 'Mac' and 'Windows'
    this.application = application; // Supported applications are 'VStitcher' and 'CLO3D'
    this.stageVersion = stageVersion; // Version of the Stage being used. Examples are '1.0.0' and '1.0.0-beta.1'
    this.sdkVersion = sdkVersion; // Version of the SDK being used. Examples are '1.0.0' and '1.0.0-beta.1'
    this.pluginVersion = pluginVersion; // Version of the plugin being used. Examples are '1.0.0' and '1.0.0-beta.1'
    this.license = license; // License of the application being used. Examples are 'Individual', 'Business', and 'Enterprise'
    this.options = options;

  }
 
  // Start Getters and Setters

  public get platform() {
    return this._platform;
  }
  public set platform(newVal: PlatformType) {
    if (newVal instanceof Array) {
      this._platform = (newVal.map((val) => val === 'darwin' ? 'Mac' : val) as PlatformType);
    } else {
      this._platform = (newVal === 'darwin' ? 'Mac' as PlatformType : newVal as PlatformType);
    }
  }

  public get application() {
    return this._application;
  }
  public set application(newVal: EnvironmentApplication<VersionType> | EnvironmentApplication<VersionType>[]) {
    this._application = newVal;
  }

  public get stageVersion() {
    return this._stageVersion;
  }
  public set stageVersion(newVal: VersionType) {
    this._stageVersion = newVal;
  }

  public get pluginVersion() {
    return this._pluginVersion;
  }
  public set pluginVersion(newVal: VersionType) {
    this._pluginVersion = newVal;
  }

  public get sdkVersion() {
    return this._sdkVersion;
  }
  public set sdkVersion(newVal: VersionType) {
    this._sdkVersion = newVal;
  }

  public get license() {
    return this._license;
  }
  public set license(newVal: LicenseType) {
    this._license = newVal;
  }
  
  // End Getters and Setters

  protected static validateVersion(version: string) {
    if (version !== '' && !version.match(/^\d+([\.-][\d\w]+)*$/)) {
      throw new RangeError(`The version ${version} is not a valid version.  Please ensure there aren't any typos.`)
    }
  }

  protected static validateLicense(license: string) {
    if (license !== '' && license !== 'Individual' && license !== 'Business' && license !== 'Enterprise' && license !== 'Solidworks') {
      throw new RangeError(`The license ${license} is not a valid license.  Please ensure there aren't any typos.`)
    }
  }

  public static splitVersionNumbers(version: string): Array<any> {
    const splitVersion = version.split(Environment.VERSION_SPLIT_CHARACTERS);
    return splitVersion.map(part => !isNaN(Number(part)) ? Number(part) : part);
  }

  public static isVersionLessThanOrEqualTo(splitVersionA: Array<any>, splitVersionB: Array<any>, index:number = 0): Boolean {
    if (splitVersionA[index] === splitVersionB[index]) {
      //Continue checking the other numbers
      if(index < Math.min(splitVersionA.length, splitVersionB.length))
        return Environment.isVersionLessThanOrEqualTo(splitVersionA, splitVersionB, index + 1);
      else return (splitVersionA.length <= splitVersionB.length);
    }
    else if (splitVersionA[index] > splitVersionB[index]) 
      return false;
    else return true;
  }

  public static isVersionGreaterThanOrEqualTo(splitVersionA: Array<any>, splitVersionB: Array<any>, index:number = 0): Boolean {
    if (splitVersionA[index] === splitVersionB[index]) {
      //Continue checking the other numbers
      if(index < Math.min(splitVersionA.length, splitVersionB.length))
        return Environment.isVersionGreaterThanOrEqualTo(splitVersionA, splitVersionB, index + 1);
      else return (splitVersionA.length >= splitVersionB.length);
    }
    else if (splitVersionA[index] < splitVersionB[index]) 
      return false;
    else return true;
  }

  public static isVersionGreaterThan(splitVersionA: Array<any>, splitVersionB: Array<any>) {
    return !Environment.isVersionLessThanOrEqualTo(splitVersionA, splitVersionB);
  }

  public static isVersionLessThan(splitVersionA: Array<any>, splitVersionB: Array<any>) {
    return !Environment.isVersionGreaterThanOrEqualTo(splitVersionA, splitVersionB);
  }

  protected static hasCompatiblePlatform(platform: PLUGIN_PLATFORM_TYPE, compatiblePlatforms: FEATURE_PLATFORM_TYPE): boolean {
    if (!compatiblePlatforms) {
      return true;
    }
    if (typeof compatiblePlatforms === 'string') {
      return platform === compatiblePlatforms;
    }
    else {
      if(!compatiblePlatforms.length) {
        return true;
      }
      return compatiblePlatforms.includes(platform);
    }
  }

  protected static areApplicationsCompatible(environmentApplication: EnvironmentApplication<PLUGIN_VERSION_TYPE>, featureApplication: EnvironmentApplication<FEATURE_VERSION_TYPE>): boolean {
    if (featureApplication.name === "") {
      return true;
    }
    else if (featureApplication.name === environmentApplication.name) {
      return Environment.hasCompatibleVersion(environmentApplication.version, featureApplication.version);
    }
    else {
      return false;
    }
  }

  protected static hasCompatibleVersion(environmentVersion: string, featureVersion: FEATURE_VERSION_TYPE): boolean {
    if (environmentVersion === '' && (featureVersion[0] !== '' || featureVersion[1] !== '')) {
      return false;
    }
    let splitPluginVersion: Array<number>;
    let splitFeatureMinVersion: Array<number>;
    let splitFeatureMaxVersion: Array<number>;
    // Verify that this has a valid plugin version
    try {
      splitPluginVersion = Environment.splitVersionNumbers(environmentVersion);
    }
    catch (e) {
      if (e instanceof RangeError) {
        return false;
      }
      else throw e;
    }
    if (featureVersion[0] !== '') {
      splitFeatureMinVersion = Environment.splitVersionNumbers(featureVersion[0]);
      if (Environment.isVersionLessThan(splitPluginVersion, splitFeatureMinVersion)) 
        return false;
    }
    if (featureVersion[1] !== '') {
      splitFeatureMaxVersion = Environment.splitVersionNumbers(featureVersion[1]);
      if (Environment.isVersionGreaterThan(splitPluginVersion, splitFeatureMaxVersion)) 
        return false;
    }
  
    return true;
  }

  protected static hasCompatibleApplicationVersion(environmentVersion: PLUGIN_VERSION_TYPE, featureVersion: FEATURE_VERSION_TYPE): boolean {
    return Environment.hasCompatibleVersion(environmentVersion, featureVersion);
  }
  
  protected static hasCompatibleStageVersion(environmentVersion: PLUGIN_VERSION_TYPE, featureVersion: FEATURE_VERSION_TYPE): boolean {
    return Environment.hasCompatibleVersion(environmentVersion, featureVersion);
  }

  protected static hasCompatibleSdkVersion(environmentVersion: PLUGIN_VERSION_TYPE, featureVersion: FEATURE_VERSION_TYPE): boolean {
    return Environment.hasCompatibleVersion(environmentVersion, featureVersion);
  }

  protected static hasCompatiblePluginVersion(environmentVersion: PLUGIN_VERSION_TYPE, featureVersion: FEATURE_VERSION_TYPE): boolean {
    return Environment.hasCompatibleVersion(environmentVersion, featureVersion);
  }

  protected static hasCompatibleApplication(environmentApplication: EnvironmentApplication<PLUGIN_VERSION_TYPE>, featureApplication: EnvironmentApplication<FEATURE_VERSION_TYPE> | EnvironmentApplication<FEATURE_VERSION_TYPE>[]): boolean {
    if (featureApplication instanceof EnvironmentApplication<FEATURE_VERSION_TYPE>) {
      return Environment.areApplicationsCompatible(environmentApplication, featureApplication);
    }
    else {
      console.log("featureApplication is an array of EnvironmentApplications");
      return featureApplication.some(application => {
        return Environment.areApplicationsCompatible(environmentApplication, application);
      })
    }
  }


  protected static hasCompatibleLicense(license: PLUGIN_LICENSE_TYPE, requiredLicense: FEATURE_LICENSE_TYPE): boolean {
    if (!requiredLicense) {
      return true;
    }
    if (typeof requiredLicense === 'string') {
      return license === requiredLicense;
    }
    else {
      if(!requiredLicense.length) {
        return true;
      }
      return requiredLicense.includes(license);
    }
  }

  protected static areEnvironmentsCompatible(pluginEnvironment: PluginEnvironment, featureEnvironment: FeatureEnvironment): boolean {
    if (!Environment.hasCompatiblePlatform(pluginEnvironment.platform, featureEnvironment.platform)) {
      return false;
    }
    if (!Environment.hasCompatibleApplication(pluginEnvironment.application, featureEnvironment.application)) {
      return false;
    }
    if (!Environment.hasCompatibleLicense(pluginEnvironment.license, featureEnvironment.license)) {
      return false;
    }
    if (!Environment.hasCompatibleVersion(pluginEnvironment.stageVersion, featureEnvironment.stageVersion)) {
      return false;
    }
    if (!Environment.hasCompatibleVersion(pluginEnvironment.pluginVersion, featureEnvironment.pluginVersion)) {
      return false;
    }
    if (!Environment.hasCompatibleVersion(pluginEnvironment.sdkVersion, featureEnvironment.sdkVersion)) {
      return false;
    }
    if (!PluginOptions.areEnvironmentOptionsCompatible(pluginEnvironment.options, featureEnvironment.options)) {
      return false;
    }
    return true;
  }
}