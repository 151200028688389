import BasicComponentInterface from "interfaces/BasicComponentInterface";
import { useEffect, useState } from "react";


interface CustomNumberInputInterface extends BasicComponentInterface {
  onChange?: (e: number) => void,
  value?: number,
  displayText?: string,
  decimalPlaces?: number
}

const CustomNumberInput = ({
  className = "",
  onChange = () => {},
  value = 0,
  displayText = "",
  decimalPlaces = 0
}: CustomNumberInputInterface) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    let val = Math.round(value * (10 ** decimalPlaces)) / (10 ** decimalPlaces);
    setLocalValue(val);
  }, [value, decimalPlaces])
  
  return (
    <label className="custom-number-input-wrapper">
      <span className="custom-number-input-label">
        {displayText}
      </span>
      <input
        type='number'
        className={`custom-number-input ${className}`}
        onChange={(e) => onChange(e.target.valueAsNumber)}
        value={localValue}
        step={1 / (10 ** decimalPlaces)}
      ></input>
    </label>
  )
}

export default CustomNumberInput;