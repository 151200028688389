import BasicComponentInterface from "interfaces/BasicComponentInterface";
import { useState } from "react";


interface WarningNotificationProps extends BasicComponentInterface {
  message: string | React.ReactNode;
  titleLeft: string | React.ReactNode;
  titleRight?: string | React.ReactNode;
  showNotification: boolean;
}

const WarningNotification = ({
  message,
  titleLeft,
  titleRight = "",
  showNotification,
}: WarningNotificationProps) => {
  const [hasClosed , setHasClosed] = useState(false);
  return (
    <>
      {showNotification && !hasClosed && (
        <div className="out-of-date-notification">
          <div className="out-of-date-notification__title">
            <div className="out-of-date-notification__title__left">
              {titleLeft}
            </div>
            <div className="out-of-date-notification__title__right">
              {titleRight}
              <div className="out-of-date-notification__title__close" onClick={() => setHasClosed(true)}>
                <div className="material-icons">close</div>
              </div>
            </div>
          </div>
          <div className="out-of-date-notification__body">
            {message}
          </div>
        </div>
      )}
    </>
  )
}

export default WarningNotification;