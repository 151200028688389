import BasicComponentInterface from "interfaces/BasicComponentInterface"
import { Setting } from "models/Environment/CustomSettings"
import { useEffect, useState } from "react"
import CustomNumberInput from "./FormComponents/CustomNumberInput"
import CustomTextInput from "./FormComponents/CustomTextInput"
import ToggleSlider from "./FormComponents/ToggleSlider"


interface CustomSettingUIProps extends BasicComponentInterface {
  settingValue: any,
  setting: Setting,
  saveSingleSetting: (newVal: any, setVal: any ) => any
}

const CustomSettingUI = ({
  settingValue,
  setting,
  saveSingleSetting
}: CustomSettingUIProps) => {
  const [localSetting, setLocalSetting] = useState(settingValue)

  useEffect(() => {
    setLocalSetting(settingValue)
  }, [settingValue])

  switch(setting.type) {
    case "BOOLEAN":
      return (
        <ToggleSlider
          className="custom-setting"
          onChange={(checked) => {
            saveSingleSetting(checked, setLocalSetting)
          }}
          checked={localSetting}
          reversed={true}
        >
          {setting.displayName}
        </ToggleSlider>
      )
    case "STRING":
      return (
        <CustomTextInput
          className="custom-setting"
          onChange={(target) => {
            saveSingleSetting(target.value, setLocalSetting)
          }}
          value={localSetting}
          displayText={setting.displayName}
        >
        </CustomTextInput>
      )
    case "INTEGER":
      return (
        <CustomNumberInput
          className="custom-setting"
          onChange={(newVal) => {
            let val = Math.round(newVal);
            saveSingleSetting(val, setLocalSetting)
          }}
          value={localSetting}
          displayText={setting.displayName}
        >
        </CustomNumberInput>
      )
    case "FLOAT":
      return (
        <CustomNumberInput
          className="custom-setting"
          onChange={(newVal) => {
            saveSingleSetting(newVal, setLocalSetting)
          }}
          value={localSetting}
          displayText={setting.displayName}
          decimalPlaces={5}
        >
        </CustomNumberInput>
      )
    default:
      return <></>
  }
}

export default CustomSettingUI;