import { useEffect } from "react";
import BasicComponentInterface from "../../../interfaces/BasicComponentInterface";


interface ToggleSliderInterface extends BasicComponentInterface {
  onChange?: (checked: boolean) => any,
  checked?: boolean,
  reversed?: boolean
}

const ToggleSlider = ({
  children = <></>,
  onChange = () => {},
  className = "",
  checked = false,
  reversed = false,
}:ToggleSliderInterface) => {

  return (
    <div className={`toggle-slider-wrapper ${className} ${reversed ? 'reversed': ''}`}>
      <div className="toggle-slider-text">
        {children}
      </div>

      <label className="toggle-slider">
        <input type="checkbox" checked={checked} onChange={(e) => {
          onChange(e.target.checked)}} />
        <span className="slider-handle"></span>
      </label>
    </div>
  )
}

export default ToggleSlider;