import { PluginEnvironmentContext } from "contexts/PluginEnvironmentContext";
import { UserSettingsContext } from "contexts/UserSettingsContext";
import BasicComponentInterface from "interfaces/BasicComponentInterface";
import { Setting, SettingsSection } from "models/Environment/CustomSettings";
import { useContext, useEffect, useState } from "react";
import CollapsingSection from "views/layouts/CollapsingSection";
import CustomSettingUI from "./CustomSettingUI";

interface CustomSettingManagerProps extends BasicComponentInterface {
  saveSettings?: (newVal: any, setVal: (...args:any) => any, apiKey: string, stagePath: string, exportPath: string, customSettings: any) => any
}

const CustomSettingsManager = ({
  saveSettings = (newVal: any, setVal: (...args:any) => any, apiKey: string, stagePath: string, exportPath: string, customSettings: any) => {
    setVal(newVal);
    (window as any).StagePluginSDK?.saveUserSettings(JSON.stringify({
      stagePath: stagePath,
      exportPath: exportPath,
      apiKey: apiKey,
      customSettings: customSettings,
    })).then((response: any) => {
      console.log(response);
    }).catch((error: any) => {
      console.log(error);
    });
  }
}: CustomSettingManagerProps) => {
  const {currentPluginEnvironment} = useContext(PluginEnvironmentContext);
  const {userSettings} = useContext(UserSettingsContext);
  const [customSettings, setCustomSettings] = useState(userSettings.customSettings);
  const [apiKey, setApiKey] = useState(userSettings.apiKey);
  const [stagePath, setStagePath] = useState(userSettings.stagePath);
  const [exportPath, setExportPath] = useState(userSettings.exportPath);
  
  useEffect(() => {
    setApiKey(userSettings.apiKey);
  }, [userSettings.apiKey])
  useEffect(() => {
    setStagePath(userSettings.stagePath);
  }, [userSettings.stagePath])
  useEffect(() => {
    setExportPath(userSettings.exportPath);
  }, [userSettings.exportPath])
  useEffect(() => {
    setCustomSettings(userSettings.customSettings);
  }, [userSettings.customSettings])

  // Update the custom settings with the default values if they aren't already set
  useEffect(() => {
    setCustomSettings((oldCustomSettings: any) => {
      const newCustomSettings: any = {};
      Object.keys(currentPluginEnvironment.customSettings.sections).forEach((sectionName: string) => {
        let section: SettingsSection = currentPluginEnvironment.customSettings.sections[sectionName];
        const newSection: any = {};
        Object.keys(section.settings).forEach((settingName: string) => {
          let setting: Setting = section.settings[settingName];
          if (oldCustomSettings && oldCustomSettings[sectionName]) {
            newSection[settingName] = oldCustomSettings[sectionName].hasOwnProperty(settingName) ? oldCustomSettings[sectionName][settingName] : setting.defaultValue;
          }
          else {
            newSection[settingName] = setting.defaultValue;
          }
        });
        newCustomSettings[sectionName] = newSection;
      });
      return newCustomSettings;
    });
  }, [currentPluginEnvironment])

  return (
    <>
    <h2 className="custom-settings-header"><strong>{currentPluginEnvironment.application.name}</strong> Settings</h2>
      {Object.keys(currentPluginEnvironment.customSettings.sections).map((sectionName: string, index) => {
        let section = currentPluginEnvironment.customSettings.sections[sectionName] as SettingsSection;
        return (
          <section className="settings-section custom-settings-section" key={`setting-section-${sectionName}-${index}`}>
            <CollapsingSection
              headerLeft={<h2><strong>{section.displayName.split(' ').slice(0, -1).join(' ')}</strong> {section.displayName.split(' ').slice(-1).join(' ')}</h2>}
            >
              {Object.keys(section.settings).map((settingName: string, settingIndex) => {
                let setting = section.settings[settingName] as Setting;
                return (
                  <CustomSettingUI
                    settingValue={(userSettings?.customSettings && userSettings.customSettings[sectionName] && userSettings.customSettings[sectionName].hasOwnProperty(settingName)) ? userSettings.customSettings[sectionName][settingName] : setting.defaultValue}
                    setting={setting}
                    saveSingleSetting={(newVal: any, setVal: any) => {
                      console.log("saving setting inside custom Setting Manager render", customSettings);
                      const newCustomSettings: any = {...customSettings};
                      console.log("newCustomSettings before Saving Custom Setting", newCustomSettings);
                      newCustomSettings[sectionName] = newCustomSettings[sectionName] || {};
                      newCustomSettings[sectionName][settingName] = newVal;
                      console.log("newCustomSettings after Saving Custom Setting", newCustomSettings);
                      saveSettings(newVal, setVal, apiKey, stagePath, exportPath, newCustomSettings);
                    }}
                    key={`setting-${settingName}-${settingIndex}`}
                  />
                )
              })}
            </CollapsingSection>
          </section>
        )
      })}
    </>
  )


}

export default CustomSettingsManager;