/**
 * @packageDocumentation
 * This is the entry file for the React app
 * 
 * Sets up Sentry error tracking.
 * 
 * Renders the initial React view.
 * 
 * Includes providers for the current {@linkcode PluginEnvironmentContext} and {@linkcode LoadingContext}.
 * Also includes the {@linkcode BrowserRouter}.
 */

import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/index.scss';
import * as Sentry from "@sentry/react";
import reportWebVitals from './reportWebVitals';
import { PluginEnvironmentProvider } from './contexts/PluginEnvironmentContext';
import { BrowserRouter } from 'react-router-dom';
import App from './views/App';
import { LoadingProvider } from './contexts/LoadingContext';
import { UserSettingsProvider } from 'contexts/UserSettingsContext';
import { UserDataProvider } from 'contexts/UserDataContext';

if (process.env.REACT_APP_SENTRY_ENVIRONMENT !== 'dev') {
  Sentry.init({
    dsn: "https://2538f1141651405da7e778dfabe47865@o1363379.ingest.sentry.io/6748527",
    integrations: [],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
  }); 
}

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  
  root.render(
    // <React.StrictMode>
      <PluginEnvironmentProvider>
        <UserSettingsProvider>
          <UserDataProvider>
            <LoadingProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </LoadingProvider>
          </UserDataProvider>
        </UserSettingsProvider>
      </PluginEnvironmentProvider>
    // </React.StrictMode>
  );

// }, 50000)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
