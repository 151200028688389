import EnvironmentApplication from "models/Environment/EnvironmentApplication";
import FeatureEnvironment from "models/Environment/FeatureEnvironment";
import EnvironmentDependentFeature from "views/components/EnvironmentDependentFeature";
import { Route, Routes } from "react-router-dom";
import BasicComponentInterface from "../../interfaces/BasicComponentInterface";


interface MainHeaderInterface extends BasicComponentInterface {

}

const MainHeader = ({
  children,
}: MainHeaderInterface) => {

  return (
    <header className="main-header">
      <Routes>
        <Route path="/loading" element={<></>} />
        <Route path="/*" element={
          <EnvironmentDependentFeature
            featureEnvironmentList={[
              new FeatureEnvironment('', new EnvironmentApplication('', ['', '']), ['', ''], ['', ''], ['0.0.0', ''])
            ]}
            fallbackFeature={(
              <div className="stage-logo">
                <img src="/images/Stg-Bottom-Alpha-Wht_200.png" alt="STAGE Logo"/>
              </div>
            )}
          >
            <></>
          </EnvironmentDependentFeature>
        } />
      </Routes>
      {children}
    </header>
  )
}

export default MainHeader;