import { DialogTypes } from "./StagePluginSDK";



/**
 * Interface for the VStitcher Settings Save Message
 */
interface VStitcherSettingsSaveMessage {
  "Settings": {
    "APIKey": string,
    "StagePath": string,
    "ExportPath": string,
    "ShouldExportAvatar": boolean,
    "ShouldAutoUpdate": boolean,
  }
}

/**
 * Interface for a single VStitcher Message
 */
interface SingleVStitcherDialogMessage {
  Type: DialogTypes,
  Message?: string,
  Path?: string,
  Key?: string,
}

/**
 * Interface for a group of VStitcher Messages
 */
interface VStitcherDialogsMessage {
  Dialogs:Array<SingleVStitcherDialogMessage>
}

/**
 * Creats the VStitcher message listener
 * @param callback Function to call after a message has been received
 */
export function CreateVStitcherMessageListener(callback: Function) {
  try {
    (window as any).BwApi.addMessageListener((message: any) => {
      let parsedMessage = JSON.parse(message);
      callback(parsedMessage);
    });
  }
  catch (e) {
    console.error("There was a problem adding the VStitcher message listener: ", e);
  }
}

/**
 * Sends a message to VStitcher
 * @param message Message to send to VStitcher
 */
export function SendVStitcherMessage(message: any) {
  try {
    (window as any).BwApi.sendMessage(JSON.stringify(message));
  }
  catch (e) {
    console.error("There was a problem sending a message to VStitcher", e);
  }
}

/**
 * Opens a list of dialogs in VStitcher
 * @param args List of dialogs to open
 */
export function OpenVStitcherDialogs(...args: [{
  dialogType: DialogTypes,
  messageText: string,
  currentPath?: string,
  Key?: string
}]
) {
  const messageJSON: VStitcherDialogsMessage = {
    Dialogs: []
  }
  args.forEach(arg => {
    const singleDialog: SingleVStitcherDialogMessage = {
      Type:arg.dialogType,
    }
    if (arg.messageText) singleDialog.Message = arg.messageText;
    if (arg.currentPath) singleDialog.Path = arg.currentPath;
    if (arg.Key) singleDialog.Key = arg.Key;
    messageJSON.Dialogs.push(singleDialog);
  });
  SendVStitcherMessage(messageJSON);
}

/**
 * Saves the settings to VStitcher
 * 
 * @param ShouldExportAvatar whether to send the avatar or not
 * @param ShouldAutoUpdate whether to auto update the model in stage or not
 * @param APIKey api key to use - retrieved from firebase
 * @param StagePath path to the STAGE executable
 * @param ExportPath folder in which to export the model
 */
export function SaveSettingsToVStitcher(APIKey: string, StagePath: string, ExportPath: string, CustomSettings: any) {
  SendVStitcherMessage({
    "Settings": {
      "APIKey": APIKey,
      "StagePath": StagePath,
      "ExportPath": ExportPath,
      "ShouldExportAvatar": CustomSettings.VStitcher?.ShouldExportAvatar || false,
      "ShouldAutoUpdate": CustomSettings.VStitcher?.ShouldAutoUpdate || false,
      "EnableEnhancedLayerBlending": CustomSettings.VStitcher?.EnableEnhancedLayerBlending || false
    }
  });
}