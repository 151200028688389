import { useEffect } from "react"
import BasicComponentInterface from "../../interfaces/BasicComponentInterface"


interface LoadingPageProps extends BasicComponentInterface {
}

const LoadingPage = ({
  children,
  className
}: LoadingPageProps) => {

  return (
    <main className={`loading-page ${className}`}>
      {children}
    </main>
  )
}

export default LoadingPage