import { useState } from "react";
import BasicComponentInterface from "../../../interfaces/BasicComponentInterface";


interface BottomFloatingButtonProps extends BasicComponentInterface {
  onClick: (el:HTMLInputElement) => any,
  disabled: boolean,
}

const BottomFloatingButton = ({className, onClick, children, disabled}: BottomFloatingButtonProps) => {

  return (
    <div className={`bottom-floating-button-container ${className}`}>
      <button 
        className="bottom-floating-button button action" 
        type="button" 
        disabled={disabled}
        onClick={(e) => {
          onClick(e.target as HTMLInputElement)
        }}
      >{children}</button>  
    </div>
  )
}

export default BottomFloatingButton;