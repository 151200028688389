// This component is a wrapper for features that automatically compares the current PluginEnvironment
//    with the passed in FeatureEnvironment list and either returns the children or an empty item;

import React, { ReactElement, ReactNode, useContext, useEffect, useState } from "react";
import { PluginEnvironmentContext } from "../../contexts/PluginEnvironmentContext";
import BasicComponentInterface from "../../interfaces/BasicComponentInterface";
import FeatureEnvironment from "../../models/Environment/FeatureEnvironment";

interface EnvironmentDependentFeatureProps extends BasicComponentInterface {
  featureEnvironmentList?: Array<FeatureEnvironment>;
  fallbackFeature?: ReactElement<any, any>;
}

const EnvironmentDependentFeature = ({
  featureEnvironmentList = [],
  children = <div>Feature Loading</div>,
  fallbackFeature = <></>
}: EnvironmentDependentFeatureProps) => {
  const [isAcceptedEnvironment, setIsAcceptedEnvironment] = useState(false)
  const {currentPluginEnvironment} = useContext(PluginEnvironmentContext);

  useEffect(() => {
    featureEnvironmentList.every(featureEnvironment => {
      if (featureEnvironment.isCompatibleWithPluginEnvironment(currentPluginEnvironment)) {
        setIsAcceptedEnvironment(true);
        return false;
      }
      else {
        return true;
      }
    });
  }, [currentPluginEnvironment, featureEnvironmentList, setIsAcceptedEnvironment])

  if (isAcceptedEnvironment) {
    return (
      <>
        {children}
      </>
    );
  }
  else {
    // return <div>This plugin isn't supported for this feature.</div>;
    return fallbackFeature;
  }

}

export default EnvironmentDependentFeature;