import BasicComponentInterface from "interfaces/BasicComponentInterface";
import { NavLink } from "react-router-dom";


interface NavItemInterface extends BasicComponentInterface {
  link: string,
  text: string,
  materialIcon?: string,
  imgIcon?: string
}

const NavItem = ({
  className = "",
  link,
  text,
  materialIcon,
  imgIcon
}: NavItemInterface) => {
  
  return (
      <NavLink
        to={link}
        className={({ isActive }) => (isActive ? 'active' : '') + ` ${className}`}
      >
        {materialIcon && <span className="material-icons">{materialIcon}</span>}
        {imgIcon && <img src={imgIcon} alt={text} />}
        <span className="nav-item-text">{text}</span>
      </NavLink>
  )

}

export default NavItem;