/**
 * This module contains the provider and reducers for the LoadingContext Module
 * @packageDocumentation
 */

import { createContext, Dispatch, Reducer, ReducerAction, ReducerState, useReducer } from "react";

class UserData {
  licensedPlugins: any = {};

  constructor() {
    this.licensedPlugins = {};
  }
  
  setLicensedPlugins(licensedPlugins: any) {
    this.licensedPlugins = licensedPlugins;
  }
}

/**
 * This interface is an interface
 */
interface UserDataContextInterface {
  userData: UserData,
  setUserData: Function
}

const UserDataContext = createContext<UserDataContextInterface>({
  userData: new UserData(),
  setUserData: (newValue: UserData, action = { type: '' }) => newValue,
});

type UserDataAction = 
  | { type: 'CLEAR_DATA' }
  | { type: 'SET_LICENSED_PLUGINS', licensedPlugins: any }

function userDataReducer (oldVal: UserData, action: UserDataAction): UserData {
  switch (action.type) {
    case "CLEAR_DATA":
      return new UserData();
    case "SET_LICENSED_PLUGINS":
      oldVal.licensedPlugins = action.licensedPlugins;
      return oldVal;
    default:
      console.log("Invalid use of userDataContext type.");
      return oldVal;
  }
}

interface UserDataProviderProps {
  children: React.ReactNode
}

function UserDataProvider ({children}: UserDataProviderProps) {
  const [userData, setUserData] = useReducer(userDataReducer, new UserData());

  return (
    <UserDataContext.Provider 
      value={{userData, setUserData}}>
      {children}
    </UserDataContext.Provider>
  )
}

export { UserData, UserDataContext, UserDataProvider };