import BasicComponentInterface from "../../interfaces/BasicComponentInterface"


interface ErrorPageProps extends BasicComponentInterface {
  errorMessage?: string
}

const ErrorPage = ({
  errorMessage,
  children,
  className
}: ErrorPageProps) => {

  return (
    <main className={`error-page ${className}`}>
      <section>
        <h1>There seems to have been an issue:</h1>
        {errorMessage ? <div>{errorMessage}</div> : <></>}
        {children}

      </section>
    </main>
  )
}

export default ErrorPage