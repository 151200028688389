/**
 * This module contains the provider and reducers for the LoadingContext Module
 * @packageDocumentation
 */

import { createContext, Dispatch, Reducer, ReducerAction, ReducerState, useReducer } from "react";

class UserSettings {
  stagePath: string;
  exportPath: string;
  apiKey: string = '';
  customSettings: any = {};

  constructor(rawData?: any) {
    this.stagePath = rawData?.stagePath || '';
    this.exportPath = rawData?.exportPath || '';
    this.apiKey = rawData?.apiKey || '';
    this.customSettings = rawData?.customSettings;
  }

  protected static validateApiKey(apiKey: string) {
    if (apiKey !== '' && apiKey.length !== 84) {
      throw new RangeError(`The API Key "${apiKey}" is not the correct length.  It should be 84 characters long.`)
    }
  }

  public hasValidApiKey() {
    if (this.apiKey.length !== 84) {
      return false;
    }
    return true;
  }
}

/**
 * This interface is an interface
 */
interface UserSettingsContextInterface {
  userSettings: UserSettings,
  setUserSettings: Function
}

const UserSettingsContext = createContext<UserSettingsContextInterface>({
  userSettings: new UserSettings(),
  setUserSettings: (newValue: UserSettings, action = { type: '' }) => newValue,
});

type UserSettingsAction = 
  | { type: 'CLEAR_SETTINGS' }
  | { type: 'SET_API_KEY', newApiKey: string }
  | { type: '', newUserSettingsVal: UserSettings }

function userSettingsReducer (oldVal: UserSettings, action: UserSettingsAction): UserSettings {
  switch (action.type) {
    case "CLEAR_SETTINGS":
      return new UserSettings();
    case "SET_API_KEY":
      oldVal.apiKey = action.newApiKey;
      return oldVal;
    default:
      if (!action.newUserSettingsVal) {
        return oldVal;
      }
      return action.newUserSettingsVal;
  }
}

interface UserSettingsProviderProps {
  children: React.ReactNode
}

function UserSettingsProvider ({children}: UserSettingsProviderProps) {
  const [userSettings, setUserSettings] = useReducer(userSettingsReducer, new UserSettings());

  return (
    <UserSettingsContext.Provider 
      value={{userSettings, setUserSettings}}>
      {children}
    </UserSettingsContext.Provider>
  )
}

export { UserSettings, UserSettingsContext, UserSettingsProvider };