import FeatureEnvironment from "./FeatureEnvironment";
import PluginEnvironment from "./PluginEnvironment";

/**
 * This Class pairs an environment to a function.
 * 
 * It is meant to be used in conjunction with the {@link EnvironmentDependentFunction} class.
 * 
 * @typeParam T is the return type of the function.
 * @typeParam U is an array of types that the passed in parameters should follow.
 */
export  class EnvironmentFunctionPair<T, U extends readonly any[]> {
  private _environments: Array<FeatureEnvironment> = [new FeatureEnvironment()];
  private _implementation: (...arg: U) => T;

  constructor(environments = [new FeatureEnvironment()], implementation: (...arg: U) => T) {
    this._environments = environments;
    this._implementation = implementation
  }

  public get environments () {
    return this._environments;
  } 
  public set environments (newEnvironments: Array<FeatureEnvironment>) {
    this._environments = newEnvironments;
  }

  public get implementation () {
    return this._implementation;
  }
  public set implementation (newImplementation: (...arg: U) => T) {
    this._implementation = newImplementation;
  }
}

/**
 * This class stores {@link EnvironmentFunctionPair}s and determines
 * which function should be called depending on the passed in variables.
 * 
 * It also ensures that each {@link EnvironmentFunctionPair} uses
 * the same configuration of parameters/return values
 * 
 * @typeParam T is the return type of the functions
 * @typeParam U is an array of types that should be passed into each function.  Not every function needs to use all of the parameters, but they must be in the right order based on type.
 */
export default class EnvironmentDependentFunction<T, U extends readonly any[]> {
  private _pairList = new Array<EnvironmentFunctionPair<T, U>>();

  /**
   * 
   * @param args a list of {@link EnvironmentFunctionPair | EnvironmentFunctionPairs} that should all have compatible return types and parameters.
 * 
 * @typeParam T is the return type of the functions
 * @typeParam U is an array of types that should be passed into each function.  Not every function needs to use all of the parameters, but they must be in the right order based on type.
   */
  constructor (...args: Array<EnvironmentFunctionPair<T, U>>) {
    this._pairList = args;
  }

  /**
   * 
   * @param pluginEnvironment should be the current plugin environment for which the function should be called
   * @param args a list of parameters that should be passed to the implementation.
   * @returns 
   */
  public runForPluginEnvironment(pluginEnvironment: PluginEnvironment, ...args: U): T {
    try {
      let returnVal;
      // Loop through each pair and check if the plugin environment is compatible with the feature environment
      // If it is, then run the implementation and return the value while breaking out of the loop
      // If it isn't, then continue to the next pair
      this._pairList.every(pair => {
        return pair.environments.every(environment => {
          if(pluginEnvironment.isCompatibleWithFeatureEnvironment(environment)) {
            returnVal = pair.implementation(...args);
            return false;
          }
          return true;
        })
      })
      return returnVal as T;
    }
    catch(e) {
      console.log('There was a problem with a function for the plugin environment', e);
      return false as T;
    }
  }
}