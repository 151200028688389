import React from "react"
import BasicComponentInterface from "../../interfaces/BasicComponentInterface";


interface MainBodyProps extends BasicComponentInterface {
}

const MainBody = ({
  children = <></>
}: MainBodyProps) => {
  return (
    <main className="main-body">
      {children}
    </main>
  )
}

export default MainBody