/**
 * This is the base module for the Plugin UI.
 * 
 * ## Initializing the current Plugin UI
 * This file updates the plugin environment based on the searchParams passed in from the frontend.
 * 
 * It expects the frontend to pass the following query parameters:
 * - `platform`
 * - `application`
 * - `version`
 * 
 * @example 
 * This is an example for VStitcher plugin version 2022.4.0 on Windows
 * 
 * 
 * ``` WEB_UI_URL.com/?platform=Windows&application=VStitcher&version=2022.4.0```
 * @module App
 */

import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PluginEnvironmentContext } from "contexts/PluginEnvironmentContext";
import EnvironmentDependentFunction, { EnvironmentFunctionPair } from "models/Environment/EnvironmentDependentFunction";
import FeatureEnvironment from "models/Environment/FeatureEnvironment";
import PluginEnvironment from "models/Environment/PluginEnvironment";
import PluginRoutes from "PluginRoutes";
import EnvironmentDependentFeature from "./components/EnvironmentDependentFeature";
import LoadingSpinner from "./components/LoadingSpinner";
import ErrorPage from "./pages/ErrorPage";
import MainBody from "./layouts/MainBody";
import MainNav from "./layouts/MainNav";
import BasicNav from "./layouts/BasicNav";
import EnvironmentApplication from "models/Environment/EnvironmentApplication";
import StageVersionNotification from "./components/VersionNotifications/StageVersionNotification";
import PluginVersionNotification from "./components/VersionNotifications/PluginVersionNotification";
import ApplicationVersionNotification from "./components/VersionNotifications/ApplicationVersionNotification";
import { UserSettings, UserSettingsContext } from "contexts/UserSettingsContext";
import CustomSettings from "models/Environment/CustomSettings";
import { API } from "helpers/API";
import { UserDataContext } from "contexts/UserDataContext";
import { LoadingContext } from "contexts/LoadingContext";
import PluginOptions from "models/Environment/PluginOptions";
import WarningNotification from "./components/VersionNotifications/WarningNotification";
import Environment from "models/Environment/Environment";

const navEnvironmentList = new Array<FeatureEnvironment>(
  new FeatureEnvironment('', new EnvironmentApplication('', ['', '']),['', ''], ['',''], ['0.0.0', ''],'')
);


const App = () => {
  const [searchParams] = useSearchParams();
  const [isCompatiblePlugin, setIsCompatiblePlugin] = useState(false);
  const {currentPluginEnvironment, updateCurrentPluginEnvironment} = useContext(PluginEnvironmentContext);
  const {userSettings, setUserSettings} = useContext(UserSettingsContext);
  const {userData, setUserData} = useContext(UserDataContext);
  const {loadingData, setLoadingData} = useContext(LoadingContext);

  const initializePluginEnvironmentWithSDK = () => {
    (window as any).StagePluginSDK.addGetPluginConfigCallback((message: any) => {
      // console.log("Received plugin config: ", message);
      try {
        let rawData = message;
        let updatedPluginEnvironment = new PluginEnvironment(message.platform, new EnvironmentApplication(rawData.applicationName, rawData.applicationVersion), rawData.pluginVersion, rawData.stageVersion, rawData.sdkVersion, rawData.license, CustomSettings.fromJSON(rawData.exportSettings), new PluginOptions(rawData?.options?.bUsesSkews || false, rawData?.options?.bUsesAvatars || false, rawData?.options?.bUsesStageAvatars || false, rawData?.options?.bRequiresLicense || false));
        // console.log("updatedPluginEnvironment: ", updatedPluginEnvironment);
        updateCurrentPluginEnvironment({type: "", updatedPluginEnvironment: updatedPluginEnvironment});
      }
      catch (error) {
        console.error("Error parsing plugin config: ", error);
        updateCurrentPluginEnvironment(new PluginEnvironment());
      }
    });
    (window as any).StagePluginSDK.getPluginConfig();
  }
  const initializePluginEnvironmentWithoutSDK = () => {
    // Pull in plugin environment from the search params
    let platform = searchParams.get('platform') || "";
    let applicationName = searchParams.get('application') || searchParams.get("application_name") || "";
    let applicationVersion = searchParams.get('application_version') || "";
    let pluginVersion = searchParams.get('version') || searchParams.get('plugin_version') || "";
    let stageVersion = searchParams.get('stage_version') || "";
    let sdkVersion = searchParams.get('sdk_version') || "";
    let license = searchParams.get('license') || "";
    let customSetting: CustomSettings | boolean = false;
    if (searchParams.get('custom_settings')) {
      try {
        customSetting = CustomSettings.fromJSON(JSON.parse(searchParams.get('custom_settings') || ""));
      }
      catch (error) {
        console.error("Error parsing custom settings: ", error);
        customSetting = false;
      }
    }
    if (!customSetting) {
      let settingsJSON = {
        "settingSections": [{
          "displayName": "Export Settings",
          "name": "VStitcher",
          "tooltipDescription": "VStitcher Settings",
          "settings": [{
            "displayName": "Include avatar in export",
            "name": "ShouldExportAvatar",
            "tooltipDescription": "Include avatar in export",
            "type": "BOOLEAN",
            "value": true
          }, {
            "displayName": "Automatically update the model in STAGE once changes are made in VStitcher",
            "name": "ShouldAutoUpdate",
            "tooltipDescription": "Automatically update the model in STAGE once changes are made in VStitcher",
            "type": "BOOLEAN",
            "value": true
          }]
        }]
      }
      if (Environment.isVersionGreaterThanOrEqualTo(Environment.splitVersionNumbers(pluginVersion), Environment.splitVersionNumbers("2022.4.4"))) {
        settingsJSON.settingSections[0].settings.push({
          "displayName": "Legacy Export Mode (Can help with washes or certain blended textures)",
          "name": "EnableEnhancedLayerBlending",
          "tooltipDescription": "Legacy Export Mode (Can help with washes or certain blended textures)",
          "type": "BOOLEAN",
          "value": false
        });
      }
      customSetting = CustomSettings.fromJSON(settingsJSON)
    }
    let updatedPluginEnvironment = new PluginEnvironment(platform, new EnvironmentApplication(applicationName, applicationVersion), pluginVersion, stageVersion, sdkVersion, license, customSetting, new PluginOptions(true, true, true, false));
    // Push the plugin environment to the context
    updateCurrentPluginEnvironment({type: "", updatedPluginEnvironment: updatedPluginEnvironment});
  }
  const initializePluginEnvironment = () => {
    if ((window as any).StagePluginSDK) {
      initializePluginEnvironmentWithSDK();
    }
    else {
      initializePluginEnvironmentWithoutSDK();
    }
  }
  const initializeApp = new EnvironmentDependentFunction(
    new EnvironmentFunctionPair(
      [new FeatureEnvironment('', new EnvironmentApplication('', ['', '']),['', ''], ['',''], ['0.0.0', ''],'')], 
      async () => {
        if ((window as any).StagePluginSDK) {
          // console.log("StagePluginSDK is available", (window as any).StagePluginSDK);
          // console.log("Trying to add getSettings callback", (window as any).StagePluginSDK.addGetSettingsCallback);
          try {
            (window as any).StagePluginSDK.addGetUserSettingsCallback((message: string) => {
              console.log("Received user settings: ", message);
              try {
                let rawData = JSON.parse(message);
                let newUserSettingsVal = new UserSettings(rawData);
                // console.log("newUserSettingsVal: ", newUserSettingsVal);
                setUserSettings({type: "", newUserSettingsVal: newUserSettingsVal});
              }
              catch (error) {
                console.error("Error parsing user settings: ", error);
                setUserSettings(new UserSettings());
              }
            });
          }
          catch (error) {
            console.error("Error adding initial callbacks: ", error);
          }
          try {
            (window as any).StagePluginSDK?.getUserSettings();
          }
          catch (error) {
            console.error("Error getting user settings: ", error);
          }
        }
      }
    ),
    new EnvironmentFunctionPair(
      [
        new FeatureEnvironment('', new EnvironmentApplication('VStitcher', ['', '']),['2.0.0', '2020.0.0']),
        new FeatureEnvironment('', new EnvironmentApplication('VStitcher', ['', '']),['2022.4.0', ''])
      ],
      async () => {
        if (!(window as any).BwApi) {
          (window as any).BwApi = {
            addMessageListener: (...args: any) => {
              let listenerId = Math.random().toString(36).substring(7);
              (window as any).BwApi.messageListeners = (window as any).BwApi.messageListeners || {};
              (window as any).BwApi.messageListeners[listenerId] = args[0];
              // console.log('Added Message Listener with the following args: ', ...args);
              // console.log(`It can be accessed at window.BwApi.messageListeners['${listenerId}']: `);
            },
            sendMessage: (str: any) => console.log('Sending Message: ', str),
            getLangCode: () => (location.search.match(/lang=([^&]+)/) || []) [1] || 'en'
          }
        }

        // Pull in user settings from the search params
        let settings: any = searchParams.get('settings');
        settings = JSON.parse(settings) || {};
        settings.apiKey = settings['APIKey']
        settings.stagePath = settings['StagePath']
        settings.exportPath = settings['ExportPath']
        settings.customSettings = {}
        settings.customSettings.VStitcher = {}
        settings.customSettings.VStitcher.ShouldExportAvatar = settings['ShouldExportAvatar']
        settings.customSettings.VStitcher.ShouldAutoUpdate = settings['ShouldAutoUpdate']
        settings.customSettings.VStitcher.EnableEnhancedLayerBlending = settings['EnableEnhancedLayerBlending']
        let api_key = searchParams.get('api_key');
        if (api_key) {
          settings.apiKey = api_key;
        }
        let newUserSettingsVal = new UserSettings(settings);
        // Push the user settings to the context
        setUserSettings({type: "", newUserSettingsVal: newUserSettingsVal});
      }
    )
  )

  useEffect(() => {
    initializePluginEnvironment();
  }, [])

  useEffect(() => {
    // console.log("evaluating currentPluginEnvironment: ", currentPluginEnvironment);
    if ((!currentPluginEnvironment.platform || !currentPluginEnvironment.application.name || !currentPluginEnvironment.pluginVersion)) {
      setIsCompatiblePlugin(false);
    }
    else {
      setIsCompatiblePlugin(true);
      initializeApp.runForPluginEnvironment(currentPluginEnvironment);
    }
  }, [currentPluginEnvironment])

  useEffect(() => {
    if (userSettings.apiKey) {
      // get list of compatible plugins
      setLoadingData({
        type: 'START_LOADING_WITH_MESSAGE',
        message: "Gathering data..."
      })
      API.getLicensedPlugins(userSettings.apiKey)
      .then (data => {
        if (data) {
          console.log("Got Licensed Plugins: ", data);
          setUserData({
            type: 'SET_LICENSED_PLUGINS',
            licensedPlugins: data
          })
          setLoadingData({
            type: 'STOP_LOADING'
          })
        }
      })
    }

  }, [userSettings.apiKey])

  if (isCompatiblePlugin) return (
    <div className="main-layout">
      <StageVersionNotification
        currentPluginEnvironment={currentPluginEnvironment}
      />
      <PluginVersionNotification
        currentPluginEnvironment={currentPluginEnvironment}
      />
      <ApplicationVersionNotification
        currentPluginEnvironment={currentPluginEnvironment}
      />
      {/* <div style={{width: "400px", wordWrap: "break-word"}}>{searchParams}</div> */}
      <EnvironmentDependentFeature
        featureEnvironmentList={navEnvironmentList}
        fallbackFeature={<BasicNav/>}
      >
        <MainNav></MainNav>
      </EnvironmentDependentFeature>
      <MainBody>
        <WarningNotification
          message={
            <p>
              This plugin has restricted access. 
              {userSettings.apiKey ? 
                " Your account does not have access to this plugin. If this should not be the case, please contact support to request access." 
              : " It looks like you aren't logged in. Please log in to get access to this plugin."}
            </p>
          }
          titleLeft="Restricted Plugin"
          showNotification={!((currentPluginEnvironment?.options?.bRequiresLicense && userData.licensedPlugins && userData.licensedPlugins[currentPluginEnvironment.application.name]) 
            || !currentPluginEnvironment.options.bRequiresLicense)}
        />
        <PluginRoutes />
      </MainBody>
      <LoadingSpinner />
    </div>
  );
  else return (
    <ErrorPage 
      errorMessage="It doesn't look like this plugin supports the web interface yet. Please contact support.  The plugin you are using has the following data:" 
      children={
        <>
          <div>Platform: "{currentPluginEnvironment.platform}"</div>
          <div>Application: "{currentPluginEnvironment.application.name}"</div>
          <div>Application Version: "{currentPluginEnvironment.application.version}"</div>
          <div>Plugin Version: "{currentPluginEnvironment.pluginVersion}"</div>
          <div>Stage Version: "{currentPluginEnvironment.stageVersion}"</div>
          <div>SDK Version: "{currentPluginEnvironment.sdkVersion}"</div>
          <div>License: "{currentPluginEnvironment.license}"</div>
          <div>API Key: "{userSettings.apiKey ? 'Received' : 'Not Received'}"</div>
        </>
      }
    />
  )
}

export default App;