import { PluginEnvironmentContext } from "contexts/PluginEnvironmentContext";
import EnvironmentApplication from "models/Environment/EnvironmentApplication";
import EnvironmentDependentFunction, { EnvironmentFunctionPair } from "models/Environment/EnvironmentDependentFunction";
import FeatureEnvironment from "models/Environment/FeatureEnvironment";
import { useContext, useEffect, useState } from "react";
import BottomFloatingButton from "views/components/FormComponents/BottomFloatingButton";
import CustomSettingsManager from "views/components/CustomSettingsManager";




const OpenInStagePage = () => {
  const {currentPluginEnvironment} = useContext(PluginEnvironmentContext);


  const openInStage = new EnvironmentDependentFunction(
    new EnvironmentFunctionPair(
      [new FeatureEnvironment('', new EnvironmentApplication('', ['', '']), ['', ''], ['', ''], ['0.0.0', ''])],
      () => {
        console.log("Open in stage");
        (window as any).StagePluginSDK?.openInStage();
      }
    )
  )

  return (
    <main className="open-in-stage-page">
      <CustomSettingsManager/>
      <BottomFloatingButton
        className="settings-save-button"
        disabled={false}
        onClick={() => openInStage.runForPluginEnvironment(currentPluginEnvironment)}
      >Open In Stage</BottomFloatingButton>
    </main>

  );
}

export default OpenInStagePage;