import BasicComponentInterface from "interfaces/BasicComponentInterface";


interface CustomTextInputProps extends BasicComponentInterface {
  onChange?: (e: any) => any,
  value?: string
  displayText?: string
}

const CustomTextInput = ({
  className,
  onChange = () => {},
  value = "",
  displayText = ""
}: CustomTextInputProps) => {
  return (
    <label className="custom-text-input-wrapper">
      <span className="custom-text-input-label">
        {displayText}
      </span>
      <input 
        type='text'
        className={`custom-text-input ${className}`}
        onChange={(e) => onChange(e.target)}
        value={value}
      ></input>
    </label>
  )
}

export default CustomTextInput;