import { Route, Routes } from "react-router-dom";
import MainHeader from "./MainHeader";


const BasicNav = () => {

  return (
    <MainHeader>
      <nav className="header-nav basic-nav">
        <Routes>
          <Route path="/" element={<h1><strong>Settings</strong></h1>} />
          <Route path="/avatars" element={<h1>Download <strong>Avatars</strong></h1>} />
          <Route path="/settings" element={<h1>Plugin <strong>Settings</strong></h1>} />
          <Route path="/render" element={<h1><strong>Render</strong> with <strong>STAGE</strong></h1>} />
          {/* <Route path="/" element={<Settings/>} /> */}
        </Routes>
      </nav>
      <div className="basic-header-spacer"></div>
    </MainHeader>
  )
}

export default BasicNav;