import { PluginEnvironmentContext } from "contexts/PluginEnvironmentContext";
import EnvironmentApplication from "models/Environment/EnvironmentApplication";
import EnvironmentDependentFunction, { EnvironmentFunctionPair } from "models/Environment/EnvironmentDependentFunction";
import FeatureEnvironment from "models/Environment/FeatureEnvironment";
import { useContext, useEffect, useState } from "react";
import { LoadingContext } from "../../contexts/LoadingContext";
import { CreateVStitcherMessageListener, SendVStitcherMessage } from "../../helpers/VStitcher";
import BasicComponentInterface from "../../interfaces/BasicComponentInterface";

const LoadingSpinner = () => {
  const {currentPluginEnvironment} = useContext(PluginEnvironmentContext);
  const {loadingData, setLoadingData} = useContext(LoadingContext);

  const initializeLoader = new EnvironmentDependentFunction(
    new EnvironmentFunctionPair(
      [new FeatureEnvironment('', new EnvironmentApplication('', ['', '']), ['', ''], ['',''], ['0.0.0', ''],'')],
      () => {
        console.log("Adding loading callbacks");
        (window as any).StagePluginSDK?.addStartLoadingCallback((message?: string) => {
          let parsedMessage = JSON.parse(message || "{}");
          console.log("Loading message: ", parsedMessage)
          setLoadingData({
            type: 'START_LOADING_WITH_MESSAGE_AND_PROGRESS',
            message: parsedMessage.message || "Loading...",
            progress: parsedMessage.progress >= 0 ? parsedMessage : -1
          });
        });
        (window as any).StagePluginSDK?.addUpdateLoadingProgressCallback((message?: string) => {
          let parsedMessage = JSON.parse(message || "{}");
          console.log("Loading message: ", parsedMessage)
          setLoadingData({
            type: 'UPDATE_LOADING_MESSAGE_AND_PROGRESS',
            message: parsedMessage.message || "Loading...",
            progress: parsedMessage.progress >= 0 ? parsedMessage : -1
          });
        });
        (window as any).StagePluginSDK?.addStopLoadingCallback(() => {
          console.log("Loading stopped");
          setLoadingData({
            type: 'STOP_LOADING'
          });
        });
      }
    ),
    new EnvironmentFunctionPair(
      [new FeatureEnvironment('', new EnvironmentApplication('VStitcher', ['', '']), ['', ''])],
      () => {
        CreateVStitcherMessageListener((parsedMessage: any) => {
          if (parsedMessage.LoadingProgress) {              
            if (parsedMessage.LoadingProgress.hasOwnProperty('ShowProgress') && parsedMessage.LoadingProgress.ShowProgress == false) {
              setLoadingData({
                type: 'STOP_LOADING'
              });
            }
            else {
              console.log("Loading message: ", parsedMessage)
              let percentage = parsedMessage.LoadingProgress.ProgressPercentage >= 0 ? parsedMessage.LoadingProgress.ProgressPercentage : -1;
              setLoadingData({
                type: 'UPDATE_LOADING_MESSAGE_AND_PROGRESS',
                message: parsedMessage.LoadingProgress.ProgressTitle || "Loading...",
                progress: percentage
              });
            }
          }
        });
      }
    )
  )
  useEffect(() => {
    // Logging to VS 
    // const consoleMessage = {
    //   Logger: [{msg:"Loading data changed", data: loadingData}]
    // }
    // SendVStitcherMessage(consoleMessage);
    console.log("Loading data changed: ", loadingData);
  }, [loadingData])

  useEffect(() => {
    initializeLoader.runForPluginEnvironment(currentPluginEnvironment)
  }, [])
  // Test block of code to make sure the loading screen is always showing
  // useEffect(() => {
  //   console.log(isLoading);
  //   if (!isLoading) {
  //     setIsLoading({newLoadingVal: true})
  //   }
  // }, [isLoading, setIsLoading])

  if (loadingData.isLoading) return (
    <div className="loading-spinner-wrapper">
      <div className="spinner-contents">
        <div className="loading-spinner"></div>
        {/* <div className="spinner-ring"><div></div><div></div><div></div><div></div></div> */}
        {loadingData.loadingMessage 
          ? <div className="progress-text">{loadingData.loadingMessage}</div>
          : <div className="progress-text">Loading...</div>
        }
        {loadingData.showLoadingProgress && 
          <div className="spinner-progress">
            <div className="progress-bar" style={{width: `${loadingData.loadingProgress}%`}}></div>
          </div>
        }
      </div>
    </div>
  )
  else return (
    <></>
  )
}

export default LoadingSpinner;