import { useContext, useEffect } from "react"
import { Link, NavLink, useLocation, useSearchParams } from "react-router-dom";
import NavItem from "views/components/Nav/NavItem";
import { PluginEnvironmentContext } from "../../contexts/PluginEnvironmentContext"
import MainHeader from "./MainHeader";
import { UserSettingsContext } from "contexts/UserSettingsContext";
import { UserDataContext } from "contexts/UserDataContext";


const MainNav = () => {
  const {currentPluginEnvironment} = useContext(PluginEnvironmentContext);
  const {userSettings} = useContext(UserSettingsContext);
  const {userData} = useContext(UserDataContext);
  const searchString = useLocation().search;
  
  useEffect(() => {
    console.log("licensed Plugins changed: ", userData.licensedPlugins)
    console.log("requires license: ", currentPluginEnvironment?.options?.bRequiresLicense);
  }, [userData.licensedPlugins])

  return (
    <MainHeader>
      <nav className="main-nav">
        {((currentPluginEnvironment?.options?.bRequiresLicense && userData.licensedPlugins && userData.licensedPlugins[currentPluginEnvironment.application.name]) 
          || !currentPluginEnvironment.options.bRequiresLicense) && (
          <>
            <NavItem
              link={`/open/`}
              text="Open"
              imgIcon="/images/Stage-JustRound-Wht_200.png"
            />
            {(userSettings.apiKey) && (
              <>
                <NavItem
                  link={`/render/`}
                  text="Render"
                  materialIcon="collections"
                />
                {/* <NavItem
                  link={`/cloud/`}
                  text="Upload"
                  materialIcon="cloud_upload"
                /> */}
                {currentPluginEnvironment.options.bUsesStageAvatars && (
                  <NavItem
                    link={`/avatars/`}
                    text="Avatars"
                    materialIcon="people"
                  />
                )}
              </>
            )}
          </>
        )}
        <NavItem
          link={`/settings/`}
          text="Settings"
          materialIcon="settings"
        />
        <NavItem
          link={`/faq/`}
          text="F.A.Q."
          materialIcon="help_outline"
        />
      </nav>
    </MainHeader>
  )
}

export default MainNav