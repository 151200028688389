import PluginEnvironment from "../models/Environment/PluginEnvironment";
import { createContext, Dispatch, Reducer, ReducerAction, ReducerState, useReducer } from "react";


interface PluginEnvironmentContextInterface {
  currentPluginEnvironment: PluginEnvironment,
  updateCurrentPluginEnvironment: Function
}

const PluginEnvironmentContext = createContext<PluginEnvironmentContextInterface>({
  currentPluginEnvironment: new PluginEnvironment(),
  updateCurrentPluginEnvironment: (newPluginEnvironment: PluginEnvironment, action = { type: '' }) => newPluginEnvironment,
});

type PluginEnvironmentAction = 
  | { type: 'REMOVE_PLUGIN_ENVIRONMENT' }
  | { type: 'REMOVE_LICENSE_DATA' }
  | { type: '', updatedPluginEnvironment: PluginEnvironment }

function pluginEnvironmentReducer (oldPluginEnvironment: PluginEnvironment, action: PluginEnvironmentAction): PluginEnvironment {
  switch (action.type) {
    case "REMOVE_PLUGIN_ENVIRONMENT":
      return new PluginEnvironment();
    case "REMOVE_LICENSE_DATA":
      return Object.assign(new PluginEnvironment(), oldPluginEnvironment, {license: ''});
    default:
      return action.updatedPluginEnvironment || new PluginEnvironment();
  }
}

interface PluginEnvironmentProviderProps {
  children: React.ReactNode
}

function PluginEnvironmentProvider ({children}: PluginEnvironmentProviderProps) {
  const [currentPluginEnvironment, updateCurrentPluginEnvironment] = useReducer(pluginEnvironmentReducer, new PluginEnvironment());

  return (
    <PluginEnvironmentContext.Provider 
      value={{currentPluginEnvironment, updateCurrentPluginEnvironment}}>
      {children}
    </PluginEnvironmentContext.Provider>
  )
}

export { PluginEnvironmentContext, PluginEnvironmentProvider };