import EnvironmentApplication from "models/Environment/EnvironmentApplication"
import FeatureEnvironment from "models/Environment/FeatureEnvironment"
import EnvironmentDependentFeature from "views/components/EnvironmentDependentFeature"
import CollapsingSection from "views/layouts/CollapsingSection"
import BasicComponentInterface from "../../interfaces/BasicComponentInterface"


interface FaqPageProps extends BasicComponentInterface {
}

const FaqPage = ({
  children,
  className
}: FaqPageProps) => {

  return (
    <main className={`faq-page ${className}`}>
      <EnvironmentDependentFeature
        featureEnvironmentList={[
          new FeatureEnvironment('', new EnvironmentApplication('CLO3D', ['', '']), ['', ''], ['', ''], ['', ''])
        ]}
        fallbackFeature={(
          <section className="faq-page-default">
            <h2>F.A.Q for this application coming soon!</h2>
          </section>
        )}
      >
        <h1>CLO3D Frequently Asked Questions</h1>
        <section>
          <CollapsingSection
            headerLeft={<h3 className="faq-page-title">My clothes are too shiny in Stage. How can I avoid this?</h3>}
            startExpanded={false}
          >
            <p>
              Some CLO3D textures have a totally black metalness map. Because the metallness sliders are multiplicative, rather than additive, this means that the metallic parts of the texture are multiplied by 0, resulting in metalness defaulting to 0 which is completely shiny. To fix this, you can either:
            </p>
            <ul>
              <li>Open the texture in an image editor and paint the metalness map white.</li>
              <li>Select the texture with the problem, and in the properties for that texture delete the metalness map as shown in the following image: <img src="/images/FAQ/CLO3D/DarkMetalnessMap.png" /></li>
            </ul>
          </CollapsingSection>
        </section>
        <section>
          <CollapsingSection
            headerLeft={<h3 className="faq-page-title">CLO3D appears to be freezing when running the plugin?</h3>}
            startExpanded={false}
          >
            <p>
              Because the plugin is running within CLO3D, it is expecting you to interact with the plugin window instead of the main CLO3D window. If you click on the main CLO3D window while the plugin is running, it will appear to freeze. To fix this, simply click on the plugin window to bring it back into focus.
            </p>
          </CollapsingSection>
        </section>
        <section>
          <CollapsingSection
            headerLeft={<h3 className="faq-page-title">My material uses an opacity map but the transparency isn't showing up in Stage. How can I fix this?</h3>}
            startExpanded={false}
          >
            <p>
              This is currently a known issue within Stage. Stage version 2023.2.0 (coming soon) will fix this issue. In the meantime, you can use the following workaround (This will require the use of an image editor like Photoshop):
            </p>
            <ol>
              <li>Open the main texture and the opacity map in an image editor.</li>
              <li>Create a mask of the main texture using the opacity map.</li>
              <li>Save the mask as a new image that supports alpha channels.</li>
              <li>Make sure the main texture map points to the newly created image.</li>
              <li>Set the opacity type to ALPHA instead of RGB.</li>
            </ol>
          </CollapsingSection>
        </section>
      </EnvironmentDependentFeature>
    </main>
  )
}

export default FaqPage