import { FEATURE_VERSION_TYPE } from "./FeatureEnvironment";
import { PLUGIN_VERSION_TYPE } from "./PluginEnvironment";


export default class EnvironmentApplication<VersionType = PLUGIN_VERSION_TYPE | FEATURE_VERSION_TYPE> {
  private _name: string = '';
  private _version: VersionType;

  /**
   * 
   * @param name Code/Name of application being used. Examples are VStitcher and CLO3D
   * @param version Version of the application being used. Examples are '1.0.0' and '1.0.0-beta.1'
   */
  constructor(name: string, version: VersionType) {
    this.name = name;
    this._version = version;
  }

  public get name() {
    return this._name;
  }
  public set name(newVal: string) {
    this._name = newVal;
  }

  public get version() {
    return this._version;
  }
  public set version(newVal: VersionType) {
    this._version = newVal;
  }
  
  hasCompatibleApplicationName(comparisonName: string) {
    return this.name === comparisonName;
  }
}