import { useEffect, useState } from "react";
import BasicComponentInterface from "interfaces/BasicComponentInterface";
import PluginEnvironment from "models/Environment/PluginEnvironment";
import { API } from "helpers/API";
import OutOfDateNotification from "./OutOfDateNotification";

interface StageVersionNotificationProps extends BasicComponentInterface {
  currentPluginEnvironment: PluginEnvironment
}

const StageVersionNotification = ({
  currentPluginEnvironment,
}: StageVersionNotificationProps) => {
  const [latestStageVersion, setLatestStageVersion] = useState("");

  // log the installed version of STAGE
  useEffect(() => {
    console.log("Installed Stage Version: ", currentPluginEnvironment.stageVersion);
  }, [currentPluginEnvironment.stageVersion]);

  // Get the current version of STAGE from Firebase
  if (currentPluginEnvironment.stageVersion) {
    console.log("Installed Stage Version is truthy: ", currentPluginEnvironment.stageVersion)
    useEffect(() => {
      API.getData(`Download/Stage/${currentPluginEnvironment.platform}/Latest/Version`).then(data => {
        if (data) {
          console.log("Stage Version: ", data);
          setLatestStageVersion(data);
        }
        else {
          console.error("Error getting latest version of STAGE");
        }

      })
    }, []);
    return (
      <OutOfDateNotification
        softwareName="Stage"
        currentVersion={currentPluginEnvironment.stageVersion}
        latestVersion={latestStageVersion}
        message="You are using an outdated version of STAGE. Please update to the latest version to ensure you are using the latest features and bug fixes. Check our website (https://stage.trapdoorcreative.com) for more information."
      />
    )
  }
  else if (currentPluginEnvironment.platform !== "Mac" && !currentPluginEnvironment.stageVersion) {
    console.log("No Stage Version");
    useEffect(() => {
      API.getData(`Download/Stage/${currentPluginEnvironment.platform}/Latest/Version`).then(data => {
        if (data) {
          console.log("Stage Version: ", data);
          setLatestStageVersion(data);
        }
        else {
          console.error("Error getting latest version of STAGE");
        }

      })
    }, []);
    return (
      <OutOfDateNotification
        softwareName="Stage"
        currentVersion={currentPluginEnvironment.stageVersion}
        latestVersion={latestStageVersion}
        message="It looks like you don't have Stage installed. Check our website (https://stage.trapdoorcreative.com/downloads) for the latest download."
      />
    )
  }
  else return null;
}

export default StageVersionNotification;