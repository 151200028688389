

export default class PluginOptions {
  bUsesSkews: boolean;
  bUsesAvatars: boolean;
  bUsesStageAvatars: boolean;
  bRequiresLicense: boolean;
  constructor(bUsesSkews: boolean = false, bUsesAvatars: boolean = false, bUsesStageAvatars: boolean = false, bRequiresLicense: boolean = false) {
    this.bUsesSkews = bUsesSkews;
    this.bUsesAvatars = bUsesAvatars;
    this.bUsesStageAvatars = bUsesStageAvatars;
    this.bRequiresLicense = bRequiresLicense;
  }

  static areEnvironmentOptionsCompatible(pluginOptions: PluginOptions, featureOptions: PluginOptions): boolean {
    if (featureOptions.bUsesSkews && !pluginOptions.bUsesSkews) {
      return false;
    }
    if (featureOptions.bUsesAvatars && !pluginOptions.bUsesAvatars) {
      return false;
    }
    if (featureOptions.bUsesStageAvatars && !pluginOptions.bUsesStageAvatars) {
      return false;
    }
    if (featureOptions.bRequiresLicense && !pluginOptions.bRequiresLicense) {
      return false;
    }
    return true;
  }
}