import CustomSettings, { SettingsSection } from "./CustomSettings";
import Environment from "./Environment";
import EnvironmentApplication from "./EnvironmentApplication";
import FeatureEnvironment from "./FeatureEnvironment";
import PluginOptions from "./PluginOptions";

export type PLUGIN_PLATFORM_TYPE = string;
export type PLUGIN_VERSION_TYPE = string;
export type PLUGIN_LICENSE_TYPE = string;


export default class PluginEnvironment extends Environment<PLUGIN_PLATFORM_TYPE, PLUGIN_VERSION_TYPE, PLUGIN_LICENSE_TYPE> {

  private _customSettings: CustomSettings;
  
  constructor(
    platform: PLUGIN_PLATFORM_TYPE = "", 
    application: EnvironmentApplication<PLUGIN_VERSION_TYPE> = new EnvironmentApplication<PLUGIN_VERSION_TYPE>('', ''), 
    pluginVersion: PLUGIN_VERSION_TYPE = "", 
    stageVersion: PLUGIN_VERSION_TYPE = "", 
    sdkVersion: PLUGIN_VERSION_TYPE = "", 
    license:PLUGIN_LICENSE_TYPE = "",
    customSettings: CustomSettings | any = new CustomSettings({}),
    options: PluginOptions = new PluginOptions(),
  ) {
    // constructor(platform = "", applicationName = "", ap = "", license = "", apiKey = "") {
    super(platform, application, pluginVersion, stageVersion, sdkVersion, license, options);   // See parent class for the definition of these variables
    if (customSettings instanceof CustomSettings) {
      this._customSettings = customSettings;
    } else {
      this._customSettings = CustomSettings.fromJSON(customSettings);
    }
  }

  public override set application(newApplication: EnvironmentApplication<PLUGIN_VERSION_TYPE>) {
    this._application = newApplication;
  }
  public override get application() {
    return this._application as EnvironmentApplication<PLUGIN_VERSION_TYPE>;
  }

  public set customSettings(newCustomSettings: CustomSettings) {
    this._customSettings = newCustomSettings;
  }
  public get customSettings() {
    return this._customSettings;
  }


  // Compare this PluginEnvironment with a passed in FeatureEnvironment
  // If the passed in Environment is valid return true
  // platform, application, and version should be defined in this 
  // to be considered compatible.  
  isCompatibleWithFeatureEnvironment = (featureEnvironment: FeatureEnvironment): boolean => {
    return Environment.areEnvironmentsCompatible(this, featureEnvironment);
  }
}