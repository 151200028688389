import Environment from "./Environment";
import EnvironmentApplication from "./EnvironmentApplication";
import PluginEnvironment, { PLUGIN_LICENSE_TYPE, PLUGIN_VERSION_TYPE } from "./PluginEnvironment";
import PluginOptions from "./PluginOptions";

export type FEATURE_PLATFORM_TYPE = string | string[];
export type FEATURE_VERSION_TYPE = [string, string];
export type FEATURE_LICENSE_TYPE = string | string[];

export default class FeatureEnvironment extends Environment<FEATURE_PLATFORM_TYPE, FEATURE_VERSION_TYPE, FEATURE_LICENSE_TYPE> {

  constructor(
    platform: FEATURE_PLATFORM_TYPE = '', 
    application: EnvironmentApplication<FEATURE_VERSION_TYPE> | EnvironmentApplication<FEATURE_VERSION_TYPE>[] = new EnvironmentApplication<FEATURE_VERSION_TYPE>('', ['', '']), 
    pluginVersion: FEATURE_VERSION_TYPE = ["", ""],
    stageVersion: FEATURE_VERSION_TYPE = ["", ""], 
    sdkVersion: FEATURE_VERSION_TYPE = ["", ""],  
    license: FEATURE_LICENSE_TYPE = '',
    options: PluginOptions = new PluginOptions()) {
    super(platform, application, pluginVersion, stageVersion, sdkVersion, license, options);   // See parent class for the definition of these variables
  }
  

  // Compare this FeatureEnvironment with a passed in PluginEnvironment
  // If the passed in Environment is valid return true
  // Any values that are the default empty string are treated as unimportant in comparisons and will be considered valid
  isCompatibleWithPluginEnvironment = (pluginEnvironment: PluginEnvironment) => {
    return Environment.areEnvironmentsCompatible(pluginEnvironment, this);
  }
}