import { useEffect, useMemo, useRef, useState } from "react";
import BasicComponentInterface from "../../interfaces/BasicComponentInterface";

interface CollapsingSectionProps extends BasicComponentInterface {
  startExpanded?: boolean;
  headerLeft?: JSX.Element;
  headerRight?: JSX.Element;
}

const CollapsingSection = ({
  className = "",
  headerLeft,
  headerRight,
  children, 
  startExpanded = true
}: CollapsingSectionProps) => {
  const [show, setShow] = useState(startExpanded);

  const sectionToHide = useRef<HTMLDivElement>(null);
  
  let collapseSection = () => {
    if (sectionToHide.current !== null) {
      let sectionHeight = sectionToHide.current.scrollHeight;
      let sectionTransition = sectionToHide.current.style.transition;

      requestAnimationFrame(() => {
        if (sectionToHide.current !== null) {
          sectionToHide.current.style.height = sectionHeight + 'px';
          sectionToHide.current.style.transition = sectionTransition;
          requestAnimationFrame(() => {
            if (sectionToHide.current !== null) {
              sectionToHide.current.style.height = '0px';
            }
          })
        }
      })
    }
  }
  let expandSection = () => {
    if (sectionToHide.current !== null) {
      let sectionHeight = sectionToHide.current.scrollHeight;
      sectionToHide.current.style.height = sectionHeight + 'px';
      sectionToHide.current.addEventListener('transitionend', function (e) {
        if (sectionToHide.current !== null) {
          sectionToHide.current.style.height = "";
        }
      }, {once: true})
    }
  }
  useEffect(() => {
    if (show) {
      expandSection();
    } else {
      collapseSection();
    }
  }, [show])
  try {
    return (
      <div className="collapsing-section-wrapper">
        <div 
          className={'collapsing-section-toggle'} 
          onClick={() => {setShow(oldVal => !oldVal)}}
        >
          <div className="collapsing-section-toggle-left">
            {headerLeft} <span className={`material-icons ${show ? '': 'pointing-right'}`}>expand_more</span>
          </div>
          <div className="collapsing-section-toggle-right">
            {headerRight}
          </div>
        </div>
        <div className={`collapsing-section ${className}`} ref={sectionToHide} style={{'height': '0px'}}>
          {children}
        </div>
      </div>
    )
  }
  catch(err) {
    console.error('There was a problem rendering the element', err);
    return <></>
  }
}

export default CollapsingSection;