import { useEffect, useState } from "react";
import BasicComponentInterface from "interfaces/BasicComponentInterface";
import PluginEnvironment from "models/Environment/PluginEnvironment";
import { API } from "helpers/API";
import OutOfDateNotification from "./OutOfDateNotification";

interface ApplicationVersionNotificationProps extends BasicComponentInterface {
  currentPluginEnvironment: PluginEnvironment
}

const ApplicationVersionNotification = ({
  currentPluginEnvironment,
}: ApplicationVersionNotificationProps) => {
  const [latestPluginVersion, setLatestPluginVersion] = useState("");
  // Get the current version of STAGE from Firebase
  useEffect(() => {
    API.getData(`Download/Plugins/${currentPluginEnvironment.application.name}/ApplicationInformation/Latest/${currentPluginEnvironment.platform}/Version`).then(data => {
      if (data) {
        console.log("Latest Plugin Version: ", data);
        setLatestPluginVersion(data);
      }
      else {
        API.getData(`Download/Plugins/${currentPluginEnvironment.application.name}/ApplicationInformation/Latest/CrossPlatform/Version`).then(data => {
          if (data) {
            console.log("Latest Plugin Version: ", data);
            setLatestPluginVersion(data);
          }
          else {
            console.error("No Latest Plugin Version Found");
          }
        })
      }
    })
  }, []);
  return (
    <OutOfDateNotification
      softwareName={currentPluginEnvironment.application.name}
      currentVersion={currentPluginEnvironment.application.version}
      latestVersion={latestPluginVersion}
      message={`You are using an outdated version of ${currentPluginEnvironment.application.name}. Please update to the latest version to ensure you are using the latest features and bug fixes. Check the ${currentPluginEnvironment.application.name} website for more information.`}
    />
  )
}

export default ApplicationVersionNotification;