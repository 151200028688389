import { FirebaseApp, initializeApp } from 'firebase/app';
import { Database, get, getDatabase, onValue, ref } from 'firebase/database';
import { Auth, getAuth, signInWithEmailAndPassword } from 'firebase/auth';

class APIClass {
  app: FirebaseApp;
  db: Database;
  auth: Auth;
  
  constructor() {
    let config = JSON.parse((process.env.REACT_APP_FIREBASE_CONFIG as string));

    this.app = initializeApp(config);
    this.auth = getAuth(this.app);
    this.db = getDatabase(this.app);

  }

  public async retrieveApiKey(email: string, password: string): Promise<any> {
    const body = JSON.stringify({'email': email, 'password': password});
    return fetch(`${process.env.REACT_APP_API_BASE_URL}api/auth/getkey`, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
        'referrerPolicy': 'no-referrer',
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data['status'] && data['status'] === '200 OK' && data['API']) {
        return data;
      } else {
        return null;
      }
    })
  }
  
  public async getLicensedPlugins(apiKey: string): Promise<any> {
    try {
      return {"CLO3D":true};
      return fetch(`${process.env.REACT_APP_API_BASE_URL}api/plugin/myplugins`, {
        method: 'POST',
        headers: {
          "Authorization": `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        }
      })
      .then(response => response.json())
      .then(data => {
        if (data['status'] && data['status'] === '200 OK' && data['PluginLicenses']) {
          
          return data['PluginLicenses'];
        } else {
          return {};
        }
      })
      .catch(e => {
        console.error(e);
        return {};
      })
    }
    catch(e) {
      console.error(e);
      return {};
    }
  }

  public async getData(path: string): Promise<any> {
    const dataRef = ref(this.db, path);
    return await get(dataRef).then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        return null;
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }

  public async getStageDefaults(): Promise<any> {
    return await this.getData(`Defaults-1-2`);
  }
}

export const API = new APIClass();