// PluginRoutes.tsx
/** 
 * This module contains the router for the web ui.
 * 
 * ---
 * ## Available Routes:
 * Certain routes are only available in certain environments. 
 * ### Default Route
 * The default route `/` renders the settings page
 * ### Avatars
 * - `/avatars`
 * ### Settings
 * - `/settings`
 * 
 * ---
 * ## Plugin Environment Setup
 * See {@link App! | App} for setting up the environment
 * @module
 */

import React, { Suspense, useState } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import FaqPage from "views/pages/FaqPage"
import LoadingPage from "views/pages/LoadingPage"
import OpenInStagePage from "views/pages/OpenInStagePage"
import EnvironmentApplication from "./models/Environment/EnvironmentApplication"
import FeatureEnvironment from "./models/Environment/FeatureEnvironment"
import EnvironmentDependentFeature from "./views/components/EnvironmentDependentFeature"
import LoadingSpinner from "./views/components/LoadingSpinner"

const AvatarsPage = React.lazy(() => import('./views/pages/AvatarsPage'));
// import Settings from "./views/pages/SettingsPage"
const SettingsPage = React.lazy(() => import('./views/pages/SettingsPage'));
const RenderWithStagePage = React.lazy(() => import('./views/pages/RenderWithStagePage'));

const avatarPageFeatureEnvironments = [
  new FeatureEnvironment('', new EnvironmentApplication('', ['', '']),['', ''], ['',''], ['0.0.0', '']),
  new FeatureEnvironment('', new EnvironmentApplication('VStitcher', ['', '']),['2.0.0', '2020.0.0']),
  new FeatureEnvironment('', new EnvironmentApplication('VStitcher', ['', '']),['2022.4.0', ''])
]

/**
 * This is a the main router for the plugin
 * 
 * @returns The elements to be rendered
 */
export default function PluginRoutes ()  {

  return (
    <Suspense fallback={<LoadingSpinner/>}>
      <Routes>
        <Route path="/avatars" element={
          <EnvironmentDependentFeature featureEnvironmentList={avatarPageFeatureEnvironments}>
            <AvatarsPage/>
          </EnvironmentDependentFeature>
        } />
        <Route path="/open" element={<OpenInStagePage/>} />
        <Route path="/render" element={<RenderWithStagePage/>} />
        <Route path="/settings" element={<SettingsPage/>} />
        <Route path="/loading" element={<LoadingPage/>} />
        <Route path="/faq" element={<FaqPage/>} />
        <Route path="/" element={<Navigate replace to='/settings' />} />
      </Routes>
    </Suspense>
  )
}